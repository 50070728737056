<template>
  <div class="">
    <header>
    </header>

    <main class="" style="">
    </main>

    <footer class="mt-auto text-white-50">
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Link',
  mounted() {
    let links = {
      'visuals': 'https://photos.google.com/share/AF1QipOnzwiuj9blnQWd9r8e5dcLtderTJKj1ixrjMqSutJ3LaggrmlsSi-S-XAKVwxOtQ?key=WWZNd3Vrai1RTU5Hdi1PNV9Uc1l2RUU1UU9OODNR',
      'birthday': 'https://photos.google.com/share/AF1QipMu42sh3SMj1e1E1niwUJ2H-5HijCfUHDE-u3iSQ187oith0VnkQsmU-IR-vKtsjg?key=UjhBOG81anFyb1Vwei1EM1RlWTlFNk5rWVg0dkd3',
      'bachelorette' : 'https://photos.google.com/share/AF1QipNqQ9yT541tNaTo1JTNN7yUYhvbJ8co8ZD1ZL0HZbMdXAAZPg0Qou7jXTBuJyHmjg?key=X3RBMXdRQ0lqclJoVF9NU3MyQktDclJJWFA4ZDFR',
      'baptism': 'https://photos.google.com/share/AF1QipOSS3mDiNm0KMDYxIrXjTvvHzfTO2a_uCvvz7yFFUcax0GssTGk1Y6F5SMM1BeUhw?key=Y1lzUG5RX0hnUEQ0TzR2d25hTlllWGl6b3lLZG1B',
      'sport': 'https://photos.google.com/share/AF1QipPeWMMNJ2WX8bAjsz4QM8lLznpEsdwDEYfWZn-iXfHx8TOw6iqiOL3PtWcfKBNndw?key=Tm40WjFIMFFQYzBtYkh6VTRyYVBlMHNZVnoyS2V3',
      'concert': 'https://photos.google.com/share/AF1QipPFEP-iDHGGi9nkfdJa7tZlex7ubBph8isQGjvdyCCpwHjeNkKrP8ukEQ_fG_oUuw?key=LXZSMl9RelM4WExuLVFZZ3VPcTk2RXdJc2VtTzZR',
      'dinamo-hajduk': 'https://photos.google.com/share/AF1QipNIJ9wfi2pdD1HZ_dKcKvBVQfxtPQyk10JNoAwUDQBUeZ_hcJjQBS-JpWdSPhr4wQ?key=aFJ1RU5oOGdreWZPWEEzZ3BFU1AyTzRqVHk4a25R',
      'real-barcelona': 'https://photos.google.com/share/AF1QipNWUTDNMW9S50ZBPTCJTzzObFXJ1WJQqIvcNBNIXat3OUaqU7rrk28fsDeBsNR1sg?key=WS1MMGo1eTlHazdFMDU4TzdqaGhSQlZVcjFlSGF3',
      'nkvarazdin': 'https://photos.google.com/share/AF1QipNKy7Zy98S2TI0t7QbwKO9g1JbJRSOLDsa8kmTpsykr_XqjXJ9XzCmjeOnpVcyodg?key=VHBtUHRPOEYzdUZLd2NwZ1U1QzAzSll2YXo3Z2FR',
      'prijovic': 'https://photos.google.com/share/AF1QipOSiBzSTaXCCOHVRc7dzIu2Bc4tcT8y7gvKX-PZraUrBQGVU9GtXtDWngwIv5JriQ?key=Unk2NUlMQ3V4RzlWbEZqaDdMdUlkNV9XSjBEUHJn',
      'i6shw2Pck2JeoC0o': 'https://photos.google.com/share/AF1QipN1p5bv84bog3tJgfq0E822gIpMErqLONTjdu-37g3T_W207DaM8_iCiEqGYfsVoA?key=R29OaTVhc2J0QmJEWG0xR2hZd25Qb25sd2dReFNR', //'https://photos.app.goo.gl/fg5rbXDFta6S86Cq6'
      'ZO9WPrup7B7hXYm0': 'https://photos.google.com/share/AF1QipNAnVHhh1DKA-AWFcALSbhXc-lI7JBOoM11A3F9cbDvW88E5K-WeoSx0vKh22atnw?key=Uk9TZ1JrX1ZKbEd3aVhBOVdlbF9pTjUtTUNObjVn',
      'nQiNH5JuBQmeoZ3i': 'https://photos.google.com/share/AF1QipP7ZC80Ky6-YloNgKMRLmUutMmSa8ctA6rrVy4tvwY7yR_2ZhPvO5kYwvEMNloLwA?key=T1czTkxpY2dfTThqNl82MlB0UjM0OHdsdV9IVzB3',
      'tHhOMCufTW6d7qrf': 'https://photos.google.com/share/AF1QipNNU7f6eZyDGszzN8TNZM66HdVmlq22_BRKc_4U-x3rG2cjgStXi4Wq3tJjL-TgAQ?key=TmNzNVZUM29UNl93bVJmREYyM09QUHEtSzlnV2tR',
      '6FogjQOARIaikYCP': 'https://photos.google.com/share/AF1QipPeoDEqqr-_kuy07wCMujdIVJ2Hgeo9Yij3bCY8o7cMqYSJ_YFv_AWuAdWI9jIY3g?key=RG15OVktZmUzVTFMWlpYblZOS0VZdl9qMks0VEJn',
      '7z0gdR5tL9kxK7Kc': 'https://photos.google.com/share/AF1QipMA0vuItuqboZrT3MV-s_RfGdVn66loMlQnNU2GSbg1f3Hd70-fQQw5Whep8js8zw?key=ZXVGbU1IR2VldmJGcjlnRmc2T1cyZEtqM3hYUjh3',
      'Rdbg8gKfBLhUUwmc': 'https://photos.google.com/share/AF1QipP-FVvP1wiNxW8CE10NI69aiGQDrOUaflPTsretOldpGr31CQioib9NrfCoPHmSlQ?key=MXZuTWwwRHl5LTI0cVlRN1B5V3NEaE9rMXZVR1Rn',
      'lWMDvgLjsV9Ae2Gq': 'https://photos.google.com/share/AF1QipOewDLrPy78Uk_sfrKlKCLYe0Hcm6s-bPC4u_JEmh8cL-D1tjo9XnD8NdN-8nAfww?key=UW8zNGFYVW0tSFlIVzhVam51Sl82NlVCWEluVWVR',
      's2iezGsnQ5HVJFxu': 'https://photos.google.com/share/AF1QipPCtgnzmB80Gnf_KDBNZ8J8LPnsRTdWRBCMm77qE98WeZOUwF8-4yNNxu6FCAaB_A?pli=1&key=UVFYVXZTZlZSZm5fTGUyWWVJS2s0RGF0RDRXOE1B',
      'h9xuK6BGq0Y5FSHO': 'https://photos.google.com/share/AF1QipMCvmmAYndguwYYkrzGdArrhRBZdXACL9ZeoNDl7i1x3Ce21Qt6DkjBU1Q1u4TNTw?key=djFwLWFtcEtDeDktMW1KNG9CVTl1RWUzZlliY0ln',
      'h2xrK7BGq0Y5FKJO': 'https://photos.google.com/share/AF1QipNjv2N_5tkRAvW50rX6nsCsYTiWSMHaimcgZEVTZQoWcMAjhfhVGJj4l_xCpe21Mw?key=MnpfNkJ1Wnpva3V4UDByODZRWHRzUnBvejhPaFN3',
      'h2xrK7BZq4Y4FKJ4': 'https://photos.google.com/share/AF1QipOc1fVFFNSZh5PSPDRbHmX8KmojgmWLeXlx6cySC6JVBXmo7JSVqebV8DsaN5KfbQ?key=Y28ydlJONDY4NVdvbk02OVlDR0NDdWFHWUdBM2p3',
      'b5xrJ7BCq4Y4FKJ5': 'https://photos.google.com/share/AF1QipN0qX_bkztGlRKpGCbpLE9YDgcx5CPAJHbVetPUsi6ba3D57icRKWAJXVf90bA3kw?key=RGZDUE5PMWVCc1NLR2UyOHlBeHp4enBPeGJzSnh3',
      'b5xr47vCq4Y4FKJ5': 'https://photos.google.com/share/AF1QipPfCgwo7l8GG4Fn9hzLyD7eHygW1OcAM3SQRycF5IzjwcpbRVA4npX7vUI072SO4g?key=enN0QTUwcGd6akFDb3lOR2ZqZzZhMUFMTklNYmlB',
      'b5tr47vyq567YKJ9': 'https://photos.google.com/share/AF1QipMdAzwa3_ppEtB5RLQVFf_vftQC07rEtR_2uliEu0AZD5TReZ6vMtxw80IkUHJbww?key=eVoxNWFxeVlVSlBzX3BMRlVTN0F6XzA4U1J3WnNn',
      'b5tr47vyq5673heb': 'https://photos.google.com/share/AF1QipNYDzXFC9AhwKKSWIm7EMKetBHAHRvMvDQs8pke8gAM5l_slaPQj-m8n30sd6QvhA?key=NGR6VWlJLVpvUzlCTnRtbWdDS0JLcE1ZTXNWUFdB',
      'kkfmnebH23KDN36J': 'https://photos.google.com/share/AF1QipMZ4esZ30jYc9tfBDQhaQutKD8P7NTs2m5-k6jVk43j0wwSsyi8H_vmipSUykeEtA?key=WUtoVmkxUGtHSi1Td3NYc3BiRjRqWVh0VnhWQjln',
      'kkfzk33H23KDm36D': 'https://photos.google.com/share/AF1QipPEx7zktMtUVc3jCJymdFEXolPSO7ewir0I78XaTI5VmNye-RIDgjhE1W81KBOjRw?key=Q2czZ2hhSXJUN1ZudTJYSUFBQTB6THJBbi11ZWNR',
      'zzfmnbeJK3KDN36J': 'https://photos.google.com/share/AF1QipMb6M-Izik22H04i69yAN55Fl6BGA5VtGQxaqdYk6q9deoRCVC1_WWRfbY5c2lq4A?key=Z1hBNmU5V1dlZ1p6d3VWQzFRbmN0bXJLMjhqMEdn',
      'krnsomrH21KDN36J': 'https://photos.google.com/share/AF1QipPywT-hkKoMxW6J8QHnjtB5cGxZEvkcPcMdOj79sklvzF80u7iaog9anSK1VT-S4A?key=SUw1X0d3V1dieF9KalcyM1prcXFOYjJhbm1fdHh3',
      'krnRLmrH2156N36J': 'https://photos.google.com/share/AF1QipPhWJSVC9VvKmrl4VnrQHz5IqTmZ0ew0MXyA7_UC7qozHKF8jAo9fICJ2X5bG2tkA?key=MGxJZHdsTEkzaGkyUG9ERGlDQ2ljX2xvNnJzX2FB',
      'krnRLmrH2154iweq': 'https://photos.google.com/share/AF1QipPH44Zk-yLZ7xldvKGyqDC0JSP4pWG41H_g8qEZ0liGu19YcWTzf_GvEyZ4RbFAwQ?pli=1&key=MmNBQXFoZVpVaFdQd0dtbEltaklpWWp0TjhONlhn', // Dora i Mario
      'krnRLmrHoe50R2Mr': 'https://photos.google.com/share/AF1QipOQ_J4pgvd70N0cwzevWafHX1onLTxZfnDNS5BBMTBxJcVBTBmY7XsTrKaPUN0QzQ?pli=1&key=YnVXYXcyR0VZN2FmOFBBRU0wSU5ZLV9PU2xZT213', // Ana Matić djevojačka
      'K48cZUoHoe50R2Ml': 'https://photos.google.com/share/AF1QipNg6RWCDauAgMSvC6MgdUNLNkHgvCIK_5E8bcXTSo1E3wT9EgVu_8h5Z5qUMbaIuw?key=Ui1jTl95S1VVWHpDc05kSnRmZ2p3a2VPWTFZcGtB', // Melani i Swen
      'kk2mn3bH23KDN37N': 'https://drive.google.com/drive/folders/12dsvsk-v4H8nXlc4W5n9VimdHJUu8aGQ',
      'kk592mnebH23mnNJ': 'https://photos.google.com/share/AF1QipNL5_HVQz2si3Jcfi1eus1ZSPRIq---xeTi3tdaGuR1y9sTc-W79RKkjyzh4CmGJg?key=ajhndThFS1NBXzBLWjlYOWdoNk9ObHVOSFZSUThB',
      'krnvik0oe5ee2MrN': 'https://photos.google.com/share/AF1QipPJ2t8Rg-ugNGdYGnQvWXp6PYUQ9NGFpOM3jV-ZDGPmUEof0cg5BWYatGYgbg_wTA?key=ZWtac3JwRERpRjBPSkVpU0pmMGtacmltRnJvbEN3',
      'zzfrykiJK3KDN34J': 'https://photos.google.com/share/AF1QipPRgIg1-bojpuQ2TZk17pKl1-ojGSdKN_4ZusR9g_QWKFiJS027MsxOZ2NBlb7WYQ?key=UGdnQVVON2V6N09tOUs5cjhEOENHMEMyWVFuNE5B',
      'yyfnnbeJK3KDN26J': 'https://photos.google.com/share/AF1QipP-TjQns3mB1aMKTzTSh-j8BvwX0wCDBEJQtnZMVs9oZ8G-fZkgUrGk0oAkw_Rufg?key=ckFEN3pldnVmNkpDTWdCUVYzWVFQQ3d3Wnc3bG13',
      'ab7M10nsI33VMZZ1': 'https://photos.google.com/share/AF1QipPRTxmq4zFTbd2gnEr5xzELZqjtHD6_t-WFPHX9LQlXzimnkKFNOiAWUPqpfNkTDA?key=cDIzN2h6NzFjbzJ1NUtaVHd3RTE3YURTbW96RDNB',
    }

    let slug = this.$route.params.slug

    console.log(slug, links[slug])

    if (slug && links[slug])
    {
      window.location.href = links[slug]
    }
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
